import ManagementMember from './ManagementMember.vue'
import ManagementMemberEdit from './Edit.vue'

export default [
    {
        path: '/management_member',
        name: 'ManagementMember',
        component: ManagementMember
    },
    {
        path: '/management_member/:id',
        name: 'ManagementMemberEdit',
        component: ManagementMemberEdit
    }
]
